.topPartBaker {
  width: 100%;
  height: 15%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;

  font-weight: 700;
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 5px;
}

.topPartBakerSmall {
  height: 7%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  font-weight: 700;
  font-size: 25px;
  line-height: 26px;

  border-bottom: solid 1px #e2e0e0;
  margin-bottom: 15px;
}

.test {
  font-family: "Montserrat" !important;

  color: #f9b333;
}

.container {
  overflow-x: auto;
  padding: 10px;
  box-sizing: border-box;
  height: 70%;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}
::-webkit-scrollbar:vertical {
  width: 8px;
}
::-webkit-scrollbar:horizontal {
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  border: 1px solid #ffffff;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #ffffff;
}

.title {
  margin-top: 20px;
  text-align: center;
}

.container tr:not(.collapsible) {
  height: 40px !important;
}

.container th:not(.collapsible) {
  max-width: 125px;
  min-width: 125px;
  padding: 0 !important;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center !important;
}

.container td:not(.collapsible) {
  height: 40px !important;
  padding: 5px !important;
  text-align: center !important;
}

.collapsible {
  height: 0 !important;
}

.annualReportPage {
  height: 80%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  padding: 0.75rem;
  margin-bottom: 0.75rem;
}

.annualTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;

  color: #000000;
}
