.mainCont {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 5%;
  box-sizing: border-box;
}

.titleCont {
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.08em;

  color: #000000;
}

.centerCont {
  height: calc(100% - 1px - 15%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5%;
  box-sizing: border-box;
}
.other {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 22px;
  text-align: center;

  color: #000000;
  margin: 0;
}

.otherWithMargin {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 0.8em;
  line-height: 22px;
  text-align: center;

  color: #000000;
  margin: 0 0 0.75rem 0;
}

.bakery {
  font-family: "AdobeHandwriting";
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  text-align: center;

  color: #000000;
  margin: 0.75rem;
}

.buttonSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
}
.address {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #000000;
  margin: 1rem;
}

.returnButton {
  width: 100%;
}
