.conformMailBoxMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 3 1;
}
.conformMailBoxFlatContextContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
}

.centerContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 3 1;
}

.helperTextAndSwitchContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.helperTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.mailBox {
  margin-right: 5px;
}

.helperTextMailBox {
  font-style: italic;
  font-weight: 100;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.08em;

  color: #000000;
}

.conformMailBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50%;
  margin-bottom: 15px;
}

.smallButtonContainer {
  height: 20px;
  width: 100%;
}

.smallButtonText {
  font-weight: 500 !important;
  font-size: 0.7em !important;
  line-height: 15px !important;
}

@media (max-width: 361px) {
  .smallButtonContainer {
    height: 20px;
    width: 100%;
  }
  .smallButtonText {
    font-weight: 500 !important;
    font-size: 0.6em !important;
  }
}

@media (max-height: 668px) {
  .smallButtonContainer {
    height: 20px;
    width: 100%;
  }

  .smallButtonText {
    font-weight: 500 !important;
    font-size: 11px !important;
    line-height: 15px !important;
  }

  .conformMailBoxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    width: 50%;
  }
}
