.mainContainer {
  display: flex;
  flex-direction: column;
  min-height: 33%;
  max-height: 66%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  padding: 0 25px;
}

.title {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 33px;
  line-height: 40px;

  text-align: center;
  letter-spacing: -0.03em;

  color: #000000;
  margin-bottom: 0.75rem;
}

.columnWidthCenter {
  flex: 1;
  display: flex;
  justify-content: center;
}

.columnWidthFlexStart {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.productPrice {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;

  color: #000000;
}

.buttonSection {
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-bottom: 0.75rem;
}
