.mainContainer {
  display: flex;
  width: 100%;
  padding: 10%;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}

.item {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10%;
  /* border: 1px solid; */
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
