.admin {
  height: 100vh;
  width: 100vw;
  position: relative;
}

.mapComponent {
  width: 100%;
  height: 100%;
  position: absolute;
}

.autocompleteSection {
  box-sizing: border-box;
  height: 100%;
  width: 33%;
  background-color: white;
  position: absolute;
  z-index: 99;
  padding: 2%;
  bottom: 0;
  right: 0;
  overflow-y: auto;
}

.autocompleteInsideLayout {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.creationSection {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
}

.bakerCreationSection {
  flex: 1;
  height: 100vh;
  overflow-y: auto;
}

.bakeryCreationSection {
  flex: 1;
}

.cont {
  display: flex;
  width: 100%;
  justify-content: space-between;

  margin-bottom: 10px;
}

.suiviCa {
  overflow-y: auto;
  width: 100%;
  height: 100vh;

  box-sizing: border-box;
  padding: 5%;
}

.updateBakery {
  overflow-y: auto;
  width: 100%;
  height: 100vh;
}

.buttonSection {
  margin: 1rem 0;
  width: 25%;
}

.marginBottom {
  margin-bottom: 2.5rem;
}

.buttonsSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectSection {
  box-sizing: border-box;
  padding: 2%;
}
