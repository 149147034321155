.conformMailBoxMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* align-items: center; */

  flex: 2;
}

.ptn {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.helperTextContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  width: 100%;
}

.helperTextMailBox {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.08em;

  color: #000000;

  width: 80%;
}

.center {
  align-self: center;
  margin-bottom: 15px;
}

.radioText span {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.08em;
}

.radioButton {
  color: #ea974a !important;
}

.radioButton .Mui-checked {
  color: #ea974a !important;
}

.solutionBottomContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-bottom: 5%;
}

.solutionParagrah {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.08em;

  color: #000000;

  text-align: left;
  margin-bottom: 5%;
}

@media (max-height: 668px) {
  .solutionText {
    font-size: 16px;
    margin-bottom: 0;
  }

  .solutionParagrah {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.08em;
    color: #000000;

    text-align: left;
    margin-bottom: 5%;
  }

  .conformMailBoxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    width: 50%;
    margin-bottom: 5%;
  }
}
