.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
}

.logo {
  margin-bottom: 25%;
}

.paragraph {
  height: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5%;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 10px;
}

.text {
  margin-bottom: 10px;
}

.button {
  width: 66%;
}
