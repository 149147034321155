.mainContainer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  justify-content: space-around;
  box-sizing: border-box;
  padding: 5%;

  position: relative;
  background-color: #fff;
}

.title {
  font-weight: 300 !important;
  font-size: 25px !important;
  line-height: 30px !important;
  letter-spacing: -0.08em !important;
  color: #ea974a !important;

  text-align: left;
}

.titleContainer {
  flex: 0.5;
}

.childrenContainer {
  height: 100%;
  padding: 5%;
}

.footerContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.drawerContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  flex: 1;
}
