.container {
  box-sizing: border-box;
  width: 100%;
  height: 90%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.checkoutContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
  width: 100%;
}
.title {
  font-weight: 400;
  font-size: 30px;
  line-height: 130%;
  /* or 39px */

  text-align: center;

  color: #000000;

  margin-bottom: 1.5em;
}

.itemLine {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 120px;
}

.boldText {
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;

  color: #000000;
}

.text {
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-align: center;

  color: #000000;
}

.buttonContainer {
  width: 90%;
}
