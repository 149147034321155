.conformMailBoxMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  flex: 1;
}

.helperTextContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
  width: 100%;
}

.helperTextMailBox {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.08em;

  color: #000000;

  width: 80%;
}

.radioText span {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.08em;
}

.radioButton {
  color: #ea974a !important;
}

.radioButton .Mui-checked {
  color: #ea974a !important;
}
