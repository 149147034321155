.container {
  width: 100%;
  height: 100%;

  margin: 10%;
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.formContainer {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  text-align: start;
  font-weight: 600;
  font-size: 1em;
}

.deleteContainer {
  height: 15%;
  display: flex;
  flex-direction: column;
}

.link {
  font-weight: 500px;
  color: #ea974a;
  text-decoration: none;
}
