.container {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.text {
  margin: 10px 0;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;

  color: #000000;
}
