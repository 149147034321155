.pageColor {
  width: 100%;
  background: #fdf8f2;
}

.topPart {
  height: 25%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.topPartBaker {
  height: 7%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  font-weight: 700;
  font-size: 25px;
  line-height: 26px;

  border-bottom: solid 1px #e2e0e0;
  margin-bottom: 15px;
}

.modificationTextBlock {
  text-align: left;
  padding: 0 15px 0px 15px;
}

.modificationTitle {
  font-family: "AdobeHandwriting";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 66px;

  letter-spacing: -0.08em;

  color: #000000;

  text-decoration: underline;
  text-decoration-color: #f9b333;
}

.modificationText {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  color: rgba(0, 0, 0, 0.6);
}

.bakeryName {
  text-align: left;
  font-family: "Montserrat";
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 50px;
  color: #fdf8f2;
  text-shadow: 4px -2px 4px rgba(0, 0, 0, 0.25);
  width: 50%;
  margin: 0 0 12px 12px;
}

.infoTextBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  text-align: left;
  padding: 0 15px;
  height: 10%;
}

.bakeryInfoText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  color: #575656;

  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;

  width: 75%;
  align-self: flex-end;
}

.children {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 65%;
}

.shadow {
  box-shadow: 4px -2px 4px rgba(0, 0, 0, 0.25);
}

.deliveryBox {
  width: 100%;
  background: #fdf8f2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  width: 50%;
}

.margin {
  margin: 12px 12px 0 0;
}

.closingText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  color: #575656;

  display: flex;
}

@media (max-width: 410px) and (max-height: 767px) {
  .bakeryName {
    font-size: 2rem;
  }
}
