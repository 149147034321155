.firstContainer {
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.registrationText {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.logo {
  width: 50%;
  margin: 0 auto;
}

.marginAuto {
  margin: auto;
}

.unconnectedPage {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5%;
}
