.AppContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.App {
  text-align: center;
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
}

@media (min-width: 850px) {
  .App:not(.admin) {
    text-align: center;
    display: flex;
    width: 600px;
    height: 100vh;
    overflow-y: hidden;
    overflow-x: hidden;
    position: relative;

    justify-content: center;
  }
}
