.inputRounded fieldset {
  border-radius: 15px !important;
}

.inputRounded {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.08em;

  color: #000000;
  background-color: #ffff;
  border-radius: 15px !important;
}
