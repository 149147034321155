.container {
  box-sizing: border-box;
  width: 100%;
  height: 90%;

  padding: 25px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #f4f4f4;
}
.title {
  font-weight: 400;
  font-size: 1.3em;
  line-height: 130%;
  /* or 39px */

  text-align: center;

  color: #000000;
}

.paragraph {
  font-weight: 400;
  font-size: 0.9em;
  line-height: 180%;
  /* or 29px */

  text-align: center;

  color: #a7a9b7;
}

.buttons > button {
  width: 100%;
  margin-bottom: 0.75rem;
}
.buttons > * {
  width: 100%;
  margin-bottom: 0.75rem;
}
