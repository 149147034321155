.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
}

.isHouseSwitchContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
}

.helperTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.mailBox {
  margin-right: 5px;
}

.helperTextMailBox {
  font-style: italic;
  font-weight: 100;
  font-size: 16px;
  text-align: center;
  letter-spacing: -0.08em;

  color: #000000;
}

.switchMaxHeight {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  max-height: 100px;
  width: 100%;
}

.marginBottom {
  margin-bottom: 0.75rem !important;
}

@media (max-height: 668px) {
  .switchMaxHeight {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    max-height: 100px;
    width: 100%;
  }
}
