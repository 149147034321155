.drawerContainer {
  width: 100%;
  height: 100%;
  bottom: 0;
  display: flex;
  flex: 1;
}

.bottomBar {
  position: absolute;

  height: 10%;
  width: 100%;
  background-color: white;
  bottom: 0;
  z-index: 99;

  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  display: flex;
  align-items: center;
}
