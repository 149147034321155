.reactSwitchCheckbox {
  height: 0;
  width: 0;
  position: absolute;
  visibility: hidden;
}

.reactSwitch {
  cursor: pointer;
  width: 100%;
  height: 100%;
  background: #f8f9fb;
  position: relative;
  transition: background-color 0.2s;
  border-radius: 50px;
}

.reactSwitch .reactSwitchButton {
  position: absolute;
  z-index: 1;
  top: 2px;
  left: 2px;
  width: calc((100% / 2) - 5px);
  height: calc(100% - 5px);
  transition: 0.2s;
  background: #ffffff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  border-radius: 50px;
}

.reactSwitchButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  font: "Inter", sans-serif;
  /* Light Mode/Black/01 */

  color: #191d31;
}

.reactSwitchCheckbox:checked + .reactSwitchButton {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.reactSwitchCheckbox:active + .reactSwitchButton {
  width: calc((100% / 2) + 15px);
}

.reactSwitchLabels {
  display: relative;
  z-index: 0;
  height: 100%;
  font: 14pt "Inter", sans-serif;
}

.reactSwitchLabels span {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100%;

  font-weight: 400;
  font-size: 14px;
  text-align: center;

  /* Light Mode/Grey/01 */

  color: #a7a9b7;
}
.reactSwitchLabels span:first-child {
  left: 0;
}
.reactSwitchLabels span:last-child {
  right: 0;
}
