.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;

  height: 65%;
  box-shadow: 4px -2px 10px rgba(0, 0, 0, 0.25);
}

.topPart {
  width: 100%;
  min-height: 25%;
  max-height: 25%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}

.infoTextBlock {
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.infoText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  /* or 143% */

  text-align: center;

  color: #575656;
}

.title {
  text-align: left;
  font-size: 1.5em;
  margin: 0 0 2% 8%;
  color: white;
}

.bakeryName {
  text-align: left;
  font-size: 1em;
  color: white;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 2.7rem;
  line-height: 50px;
  text-align: end;
  /* or 100% */

  color: #fdf8f2;
  text-shadow: 4px -2px 4px rgba(0, 0, 0, 0.25);
  margin: 0 12px 12px 0;
  width: 75%;
}

.margin {
  margin: 12px 12px 0 0;
}
