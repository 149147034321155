.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.habitTopBar {
  height: 15%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.walletContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
