.container {
  box-sizing: border-box;
  width: 100%;
  height: 80%;
  padding: 0.75rem;
  background: #f4f4f4;
}

.title {
  font-weight: 400;
  font-size: 30px;
  line-height: 130%;
  /* identical to box height, or 39px */

  text-align: center;

  color: #000000;
  background: #f4f4f4;
  height: 10%;
}

.text {
  font-weight: 500;
  font-size: 18px;
  line-height: 17px;
  align-items: center;
  text-align: center;

  color: #262626;
}

.marginBottom {
  margin-bottom: 2.5rem;
}

.centerFlex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
