.container {
  box-sizing: border-box;
  width: 100%;
  height: 90%;

  padding: 25px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #f4f4f4;
}

.paymentContainer {
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  padding: 25px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #f4f4f4;
}
