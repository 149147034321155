.container {
  display: flex;
  flex-direction: column;
}

.container:not(:last-child) {
  margin-bottom: 2rem;
}

.mainContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
}

.mainContainer:not(:last-child) {
  margin-bottom: 25px;
}

.productContainer {
  text-align: start;
  -ms-word-break: break-all;
  word-break: break-all;

  /* Non standard for WebKit */
  word-break: break-word;

  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  width: 50%;
}

.divider {
  width: 75%;
  height: 1px;
  background-color: #c4c4c443;
  align-self: center;
}

.price {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.2em;
  letter-spacing: -0.08em;

  color: #000000;
}

.description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.08em;

  color: #000000;
}

.name {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 24px;
  letter-spacing: -0.08em;

  color: #000000;
  margin-bottom: 13px;
}
