.mainContainer {
  height: 80%;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: space-evenly;
  padding: 0 15px;
}

.rowContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
}

.verticalContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.topContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  margin-bottom: 11px;
}

.bottomContainer {
  height: 50%;
  /* width: 100%; */
}

.fatRectangle {
  flex: 3;
  height: 100%;
  border-radius: 25px;
}

.firstSmallRectangle {
  margin-bottom: 11px;
}

.smallRectangles {
  margin-right: 11px;
  height: 50%;
  border-radius: 25px;
}

.slimRectangle {
  width: 100%;
  height: 100%;
  border-radius: 25px;
}
