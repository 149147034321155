.container {
  box-sizing: border-box;
  width: 100%;
  height: 90%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
}

.walletAmountContainer {
  width: 100%;
  height: 2em;

  background: #e2e0e0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.walletContainer {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.title {
  font-weight: 400;
  font-size: 30px;
  line-height: 130%;
  /* or 39px */

  text-align: center;

  color: #000000;
}

.amount {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 2em;

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;

  color: #000000;
}

.text {
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  text-align: left;
  text-decoration: underline;
  text-decoration-color: #898a8d;

  color: #898a8d;
}

.amountsContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.amountItem {
  margin-bottom: 0.75rem;
}

.buttonContainer {
  width: 90%;
  align-self: center;
}

.errorSection {
  margin-bottom: 5px;
}

.buttonSection {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
