.totalLine {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 0.75rem;
}

.totalTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;

  color: #000000;
}

.columnWidthCenter {
  flex: 1;
  display: flex;
  justify-content: center;
}

.columnWidthEnd {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.columnWidthFlexStart {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.productPrice {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.04em;

  color: #000000;
}
