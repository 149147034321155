.container {
  width: 100%;
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  align-items: center;
}

.text {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.08em;
}

.dateComponent {
  display: flex;
  flex-direction: row;

  justify-content: space-between;
  margin-left: 5%;
}

.marginRight {
  margin-right: 5px;
}

.commandComponent {
  margin-right: 5%;
}
