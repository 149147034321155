.baseButton {
  width: 100%;
  border-radius: 25px !important;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.outlinedButton {
  border: solid 1px #ea974a !important;
}

.fullButton {
  background-color: #ea974a !important;
}

.buttonText {
  color: #ffffff !important;
}

.buttonOutlinedText {
  color: #ea974a !important;
}

.confirmButtonDisabled {
  background-color: lightgray !important;
}
