.container {
  width: 100%;
}

.horizontalMargin {
  width: 66%;
  margin: 0.75rem auto;
}

.marginBottom {
  margin-bottom: 1rem;
}

@media (max-height: 668px) {
  .containerCarousel {
    width: 85%;
    margin: 0.75rem auto;
  }
}
