.mainContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 70vh;
}

.typography {
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.08em;

  color: #000000;
  text-align: justify;
}
