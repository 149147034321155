.container {
  height: 100%;
  width: 100%;
  position: relative;
}

.mapComponent {
  width: 100%;
  height: 80%;
  position: absolute;
}

.autocompleteSection {
  box-sizing: border-box;
  height: 50%;
  width: 100%;
  border-radius: 25px 25px 0 0;
  background-color: white;
  position: absolute;
  z-index: 99;
  bottom: 0;
}

.autocompleteInsideLayout {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleCont {
  height: 15%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.autocompleteTitle {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 25px;
  line-height: 30px;
  letter-spacing: -0.08em;

  color: #000000;
}

.placesContainer {
  /* height: 50%; */
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5%;
}

.buttonSection {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  padding: 5%;
  box-sizing: border-box;
}

.helperText {
  font-size: 14px !important;
  margin-bottom: 5% !important;
}

.baseButton {
  width: 100%;
  border-radius: 25px;
  max-height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nextButton {
  background-color: #ea974a;
}

.buttonText {
  color: #ffffff !important;
}

.marginBottom {
  margin-bottom: 0.75rem !important;
}
