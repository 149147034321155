.mainContainer {
  box-sizing: border-box;
  width: 100%;
  height: 90%;
  padding: 0.75rem;
}

.walletContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.75rem;
  box-sizing: border-box;
}

.monthContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.monthText {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
  text-decoration: underline;
  text-decoration-color: #f9b333;
  text-decoration-thickness: 3px;
}

.calendarContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100%;
  height: 600px;
  padding: 1rem;
  box-sizing: border-box;
}

.dot {
  margin: 2px auto 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.smallDot {
  background: #ffcf8a;
}
.noSmallDot {
  background: #ffce8a00;
}
