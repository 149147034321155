.line {
  height: 7%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
}

.bigElement {
  width: 60%;
  text-align: left;
}

.smallElement {
  width: 25%;
  text-align: end;
}

.caption {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 130%;
  /* or 18px */

  color: #000000;
}
