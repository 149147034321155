.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  align-items: center;

  box-sizing: border-box;
  padding: 7%;
  background: #fdf8f2;
}

.form {
  height: 350px;
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;
}

.buttonSection {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.registrationText {
  width: 100%;
  height: 10%;
  margin-bottom: 0.75rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}

.logo {
  width: 66%;
  margin: 0 auto;
}

.logoAndForm {
  height: 70%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
