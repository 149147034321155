.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  align-items: center;

  width: 100%;
  height: 100%;
  padding: 0 10%;
}

.mainSection {
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textSection {
  height: 30%;
}

.buttonSection {
  height: 10%;
  width: 100%;
}

.deleteSuccess {
  font-weight: 400;
  font-size: 1.3em;
}
