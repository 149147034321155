.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  align-items: center;

  width: 100%;
  height: 100%;
}

.mainSection {
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textSection {
  height: 30%;
  margin: 0 15%;
}

.title {
  font-weight: 600;
  font-size: 1.5em;
}
.text {
  font-size: 1em;
}

.connectSection {
  height: 10%;
  margin: 5% 15%;
}

.alreadyHaveAccountText {
  font-size: 0.8em;
  font-weight: 300;
  color: #a7a9b7;
}

.link {
  text-decoration: none;
  font-size: 0.8em;
  font-weight: 300;
  color: #ea974a;
}
