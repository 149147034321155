.container {
  text-align: left;
  margin: 5% 10%;
  /* height: 100%; */
}

.title {
  text-align: center;
  font-family: "AdobeHandwriting";
  font-weight: 400;
  font-size: 30px;
  line-height: 66px;

  letter-spacing: -0.08em;

  color: #000000;

  text-decoration: underline;
  text-decoration-color: #f9b333;
}

.subTitles {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 130%;

  color: #f9b333;
}

.orderContainer {
  height: 100%;

  display: flex;
  flex-direction: column;
}

.maxHeight {
  max-height: 50px;
}

.maxHeight:not(:last-child) {
  max-height: 50px;
  margin-bottom: 0.5rem;
}

.orderLine {
  margin: 0 1rem;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.text {
  font-family: "Montserrat";
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  letter-spacing: -0.08em;

  color: #000000;
}
