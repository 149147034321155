.registrationFormContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.formContainer {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.inputRounded fieldset {
  border-radius: 15px !important;
}

.textFieldWrapper {
  margin: 0 10%;
}

.buttonSection {
  height: 15%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.errorSection {
  margin-bottom: 5px;
}

.baseButton {
  width: 100%;
  border-radius: 25px;
}

.confirmButton {
  background-color: #ea974a;
}

.confirmButtonDisabled {
  background-color: lightgray;
}

.buttonText {
  color: #ffffff !important;
}
