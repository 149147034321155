.react-calendar {
  width: 400px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  font-family: "Montserrat";
  line-height: 1.125em;
  border: 0px;
}

.react-calendar__navigation button {
  font-family: "Montserrat";
  color: #ea974a;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}

.react-calendar__tile {
  font-family: "Montserrat";
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #ea974a;
  border-radius: 6px;
}

.react-calendar__tile--now:not(:disabled) {
  background: #ea974a33;
  border-radius: 6px;
  font-weight: bold;
  color: #ea974a;
}

.react-calendar__tile--now:not(:disabled):enabled:hover,
.react-calendar__tile--now:not(:disabled):enabled:focus {
  background: #ea974a33;
  border-radius: 6px;
  font-weight: bold;
  color: #ea974a;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: #ea974a;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #ea974a;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

.react-calendar__tile--range {
  background: #f8f8fa;
  color: #ea974a;
  border-radius: 0;
}

.react-calendar__tile--rangeStart {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: #ea974a;
  color: white;
}

.react-calendar__tile--rangeEnd {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #ea974a;
  color: white;
}

.react-calendar__month-view__days__day--weekend {
  color: #222;
}
.react-calendar__month-view__days__day--weekend:disabled {
  color: #1010104d;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #1010104d;
}
