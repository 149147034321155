.weekStrip {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.weekDayStrip {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.dayContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.day {
  margin: 10px 0;
  width: 42px;
  height: 42px;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectedDay {
  background-color: #ea974a;
  color: #ffffff !important;
  font-weight: 700 !important;
}

.currentDay {
  font-weight: bold;
}

.dayFont {
  margin-top: 0;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 5px;
  text-align: center;

  color: #000000;
}

.weekDay {
  margin: 0;
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  text-align: center;

  color: rgba(69, 64, 64, 0.53);
}

.smallDot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.orange {
  background: #ffcf8a;
}

.grey {
  background: #9c9a9a;
}

.noDot {
  width: 10px;
  height: 10px;
}
