.conformMailBoxMainContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  flex: 3;
}

.solutionText {
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.08em;

  color: #ea974a;
  text-align: left;
  margin-bottom: 5%;
}

.solutionParagrah {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.08em;

  color: #000000;

  text-align: left;
  margin-bottom: 5%;
}

.helperTextAndSwitchContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.helperTextContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.helperTextMailBox {
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: -0.08em;

  color: #000000;
}

.conformMailBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50%;
  margin-bottom: 5%;
}

.solutionBottomContainer {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-bottom: 5%;
}

@media (max-height: 668px) {
  .solutionText {
    font-size: 16px;
    margin-bottom: 0;
  }

  .solutionParagrah {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.08em;
    color: #000000;

    text-align: left;
    margin-bottom: 5%;
  }

  .conformMailBoxContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 33px;
    width: 50%;
    margin-bottom: 5%;
  }
}
