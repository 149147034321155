.registrationFormContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: auto;
}

.registrationText {
  flex: 1;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.formContainer {
  width: 100%;
  flex: 2;
}

.formContainer > * {
  margin: 2% 10% 1rem 10%;
}

.buttonSection {
  flex: 1;
  margin: 0 10% 1rem 10%;
}

.errorSection {
  margin-bottom: 5px;
}

.baseButton {
  width: 100%;
  border-radius: 25px;
}

.confirmButton {
  background-color: #ea974a;
}

.confirmButtonDisabled {
  background-color: lightgray;
}

.buttonText {
  color: #ffffff !important;
}
