.container {
  height: 600px;
  box-sizing: border-box;
  padding: 1rem;
}

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.galleryContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.galery {
  box-sizing: border-box;
  height: 90%;
  width: 100%;
  overflow-y: auto;
  display: flex;
  justify-content: space-around;
  align-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.image {
  /* flex: 1; */
  width: 150px;
  height: 150px;
  border-radius: 15px;
  object-fit: cover;
  object-position: center;
  margin: 0.5rem 0;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.3));
  border: 1px solid #ea974a00;
}

.falseDiv {
  width: 150px;
  height: 150px;
}

.selected {
  border: 1px solid #ea974a;
  opacity: 0.5;
}
