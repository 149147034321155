.formContainer {
  width: 100%;
  height: 35%;
  display: flex;
  flex-direction: column;
}

.textBoxContainer {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textFieldWrapper {
  margin: 0 10% 25px 10%;
}

.buttonSection {
  display: flex;
  flex-direction: column;
  height: 125px;
}

.errorSection {
  margin-bottom: 4%;
}

.baseButton:not(:last-child) {
  margin-bottom: 4%;
}

.confirmButton {
  background-color: #ea974a;
}

.usePhoneButton {
  border: solid 1px #ea974a;
  color: #ea974a;
}

.confirmButtonDisabled {
  background-color: lightgray;
}

.buttonText {
  color: #ffffff !important;
}

.usePhoneButtonText {
  color: #ea974a !important;
}

.forgotPass {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 17px;
  letter-spacing: -0.08em;

  color: #000000;
}
