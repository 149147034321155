.container {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.iconContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.inactiveText {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  letter-spacing: -0.08em;

  color: #a7a9b7;
}

.activeText {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;

  letter-spacing: -0.08em;

  color: #ea974a;
}
