.date {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 0.75rem;
}

.text {
  font-size: 18px;
  font-weight: 500;
}

.marginRight {
  margin-right: 5%;
}

.marginLeft {
  margin-left: 5%;
}
