.date {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.text {
  font-size: 1.45em;
  font-weight: 300;
}

.marginRight {
  margin-right: 5%;
}

.marginLeft {
  margin-left: 5%;
}
