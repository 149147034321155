.rectangle {
  border-radius: 10px;
  width: 95px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 31px;
  letter-spacing: -0.05em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  color: #000000;
}

.selected {
  background: #ea974a;
}

.notSelected {
  background: #e2e0e0;
}
