.registrationText {
  width: 100%;
  height: 7%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
}
