.productContainer {
  margin-bottom: 15px;
}

.productLine {
  width: 100vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.75rem;
}

.container {
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(4, 1fr);
  /* gap: 5%; */
  box-sizing: border-box;
  padding: 10px;
  align-items: center;
}
.three {
  grid-template-columns: repeat(3, 1fr);
}

.columnWidthCenter {
  justify-self: center;
}

.columnWidthStart {
  justify-self: start;
}

.columnWidthEnd {
  justify-self: end;
}

.centerText {
  max-width: 100px;
  text-align: center;
}

.valueBadge {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;

  border-radius: 5px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  text-align: center;
  letter-spacing: -0.08em;

  color: #ea974a;
}

.divider {
  padding: 0 15px;
}

.productName {
  /* width: 10px; */
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  /* identical to box height */

  letter-spacing: -0.08em;

  color: #000000;
}

.productPrice {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  /* identical to box height */

  letter-spacing: -0.08em;

  color: #000000;
}
