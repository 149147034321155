.container {
  width: 100%;
  overflow-y: auto;
  box-sizing: border-box;
  padding: 15px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
}

.link {
  background-color: #ea974a;
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.linkDone {
  background-color: grey;
  height: 30px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.links {
  display: flex;
  flex-direction: column;
}

.infoContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.infoColumnContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.marginRight {
  margin-right: 5px;
}
.marginBottom {
  margin-bottom: 5px;
}

.productContainer {
  width: 66%;
  margin: auto;
  margin-top: 20px;
}

.keyTypeContainer {
  width: 66%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.buttonContainer {
  width: 66%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
