.topPartBaker {
  height: 7% !important;

  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  text-align: center !important;

  font-weight: 700 !important;
  font-size: 25px !important;
  line-height: 26px !important;

  border-bottom: solid 1px #e2e0e0 !important;
}

.padding {
  padding: 0 5% !important;
  text-align: left !important;
}

.title {
  font-family: "AdobeHandwriting";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 66px;

  letter-spacing: -0.08em;

  color: #000000;

  text-decoration: underline;
  text-decoration-color: #f9b333;
  text-align: center;
}

.description {
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: rgba(0, 0, 0, 0.6);
}
.container {
  overflow-y: auto;
  height: 65%;
  padding: 5% !important;
}

.formContainer {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.buttonSection {
  display: flex !important;
  justify-content: space-between !important;
}

.buttonBase {
  width: 33%;
}

.validateButton {
  background-color: #ea974a !important;
}
