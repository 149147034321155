.container {
  width: 100%;
}

.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pageColor {
  background-color: #fdf8f2;
}

.titleHandwriting {
  font-family: "AdobeHandwriting" !important;
  font-weight: 400 !important;
  font-size: 30px !important;
  line-height: 66px !important;

  letter-spacing: -0.08em !important;

  color: #000000 !important;
  text-align: left;

  text-decoration: underline !important;
  text-decoration-color: #f9b333 !important;
}

.categoryName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 1.8em;
  letter-spacing: -0.03em;

  color: #000000;
  text-align: left;
}

.categoryTitle {
  display: flex;
  justify-content: space-between;
}

.tmpImageDiv {
  height: 60%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.mainContainer {
  height: 77vh;
  padding: 15px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}
.imageContainer {
  height: 10%;
  display: flex;
  flex-direction: column;
}

.marginBottom {
  margin-bottom: 15px;
}

.marginTop {
  margin-top: 15px;
}

.divider {
  height: 2px;
  background-color: #c4c4c443;
}

.productSection {
  overflow-y: auto;
  height: 60%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
}

.productSection {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.habitProductSection {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.sectionAddProduct {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonAddProduct {
  width: 271px !important;
  height: 50% !important;

  background: #ea974a !important;
  border-radius: 15.5px !important;
  border: none !important;

  font-weight: 500 !important;
  font-size: 15px !important;
  /* line-height: 18px; */
  text-align: center !important;
  letter-spacing: -0.08em !important;

  color: #ffffff !important;
  font-family: "Montserrat", sans-serif !important;
  text-transform: capitalize !important;

  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-right: 5%;
}

@media (max-height: 668px) {
  .tmpImageDiv {
    display: none;
  }

  .habitTopSection {
    height: 35%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .habitProductSection {
    overflow-y: auto;
    height: 65%;
    display: flex;
    flex-direction: column;
  }
}
