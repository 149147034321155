.amountContainer {
  background: #fdf8f2;
  border-radius: 22px;
  width: 90px;
  filter: drop-shadow(4px -2px 4px rgba(0, 0, 0, 0.25));

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: flex-end;
}

.amount {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: #575656;
}

.amountContainerAlternative {
  background: #ea974a;
  border-radius: 22px;
  width: 90px;
  filter: drop-shadow(4px -2px 4px rgba(0, 0, 0, 0.25));

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: flex-end;
}

.amountAlternative {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ffffff;
}
