.topPartBaker {
  width: 100%;
  height: 15%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;

  font-weight: 700;
  font-size: 20px;
  line-height: 26px;

  border-bottom: solid 1px #e2e0e0;
  margin-bottom: 5px;
}

.dateText {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.08em;
}

.orders {
  height: calc(100% - 15vh - 10% - 15px);

  overflow-y: auto;
  display: flex;
  flex-direction: column;
}
