.container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.paragraph {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 10px;
}

.text {
  margin-bottom: 10px;
}

.button {
  width: 66%;
}
