.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  align-items: center;

  width: 100%;
  height: 100%;
  padding: 0 10%;
}

.mainSection {
  height: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textDeleteSection {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.textDeleteSubSection {
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.title {
  font-weight: 600;
  font-size: 1.5em;
}
.text {
  font-size: 1em;
}

.buttonSection {
  height: 15%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
