.marginAuto {
  margin: auto;
}

.container {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 5%;
}

.bakeryName {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 50px;
  /* or 100% */

  text-align: center;

  color: #000000;
}

.smallContainer {
  height: 25%;
  box-sizing: border-box;
}

.bigContainer {
  height: 40%;
  box-sizing: border-box;
}

.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.spaceAround {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}

.padding {
  padding: 2%;
}

.divider {
  height: 6px;
  width: 70%;
  margin: 0 auto;
  background: #ea974a;
  border-radius: 4px;
}

.smallWidth {
  width: 50%;
}

.alignEnd {
  align-self: end;
}

.specialText {
  font-family: "AdobeHandwriting";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 20px;
  /* or 111% */

  text-align: right;

  color: #000000;
}

.description {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.05em;

  color: #000000;

  text-align: left;
}

.icons {
  display: flex;
  align-items: center;
}

.icons:not(:last-child) {
  margin-bottom: 0.75rem;
}

.marginRight {
  margin-right: 10px;
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
