.image {
  background-color: #c4c4c443;
  width: 125px;
  height: 90px;
  border-radius: 25px;

  display: flex;
  justify-content: flex-end;
}

.longBadge {
  position: relative;
  height: 30px;
  width: 75px;
  border-radius: 15.5px;
  justify-content: space-around;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.icons {
  transform: scale(0.5);
  fill: white;
  max-height: 100%;
}
