.container {
  height: 10%;
  width: 100%;
  margin-top: -10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card {
  /* bottom: 0; */
  width: 100%;
  height: 100vh;
  background-size: cover;
  border-radius: 25px 25px 0 0;
  box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
  will-change: transform;
  text-align: center;
  cursor: grab;
  touch-action: none;
  user-select: none;
  -webkit-user-select: none;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: white;
  position: relative;
}

.nothContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.children {
  position: absolute;
  top: -15px;
  left: 20px;
  /* background: green; */
}
