.page {
  width: 100%;
  height: auto;
  text-align: justify;
  padding: 10px;
  box-sizing: border-box;
}

h2 {
  text-align: center;
}
.main {
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
.main > * {
  margin-bottom: 25px;
}
