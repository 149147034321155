.container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  align-items: center;

  width: 100%;
  height: 100%;
}

.thanks {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 130%;
  /* or 104px */

  text-align: center;

  color: #000000;
  margin: 0.75rem 0;
}

.text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 130%;
  /* or 58px */

  text-align: center;

  color: #a7a9b7;
}

.button {
  width: 66%;
  margin: 0 auto;
}

.logo {
  width: 50%;
  margin: 0 auto;
}
